import React from 'react'
import { DataUnit } from '../../../api/CommonApi'
import { PText } from '@porsche-design-system/components-react'

interface StartingBalanceLabelProps {
  startingBalance?: number
  dataUnit?: DataUnit
  balanceError: boolean
}

const StartingBalanceLabel = ({
  startingBalance = 0,
  dataUnit = 'GB',
  balanceError,
}: StartingBalanceLabelProps): JSX.Element => {
  return (
    <div className='starting-balance'>
      <PText data-testid={'starting-balance-headline'} color={balanceError ? 'contrast-low' : 'primary'}>
        {!balanceError && `/ ${startingBalance} ${dataUnit}`}
      </PText>
    </div>
  )
}

export default StartingBalanceLabel
