import React from 'react'
import { PTabs, PTabsItem } from '@porsche-design-system/components-react'
import { Col, Container, Row } from 'react-bootstrap'
import { RegisteredBanner } from '../../registered/registered-banner/RegisteredBanner'
import { useIntl } from 'react-intl'
import { EuRel3ActiveStateData } from '../../../model/uistate/ConnectivityProductUIState'
import { useConnectivityProductUIState } from '../../../hooks/connectivity-product-ui-state-hooks'
import CurrentDataVolume from './current-data-volume/CurrentDataVolume'
import PreviousDataVolume from './previous-data-volume/PreviousDataVolume'
import './EuRel3Active.scss'

const EuRel3Active = (): JSX.Element => {
  const productUiState = useConnectivityProductUIState()
  const dataBalance = (productUiState.data as EuRel3ActiveStateData)?.dataBalance
  const intl = useIntl()

  return (
    <Container fluid data-testid='eu-rel3-active'>
      <Row>
        <Col xs={12} lg={5}>
          <div className='data-volume-flexbox'>
            <PTabs theme='auto'>
              <PTabsItem
                className={'rel3-data-volume-tab'}
                label={intl.formatMessage({ id: 'app.rel3.active.dataVolume.current.heading' })}
              >
                <CurrentDataVolume dataBalance={dataBalance?.currentPeriod} />
              </PTabsItem>
              <PTabsItem
                className={'rel3-data-volume-tab'}
                label={intl.formatMessage({ id: 'app.rel3.active.dataVolume.previous.heading' })}
              >
                <PreviousDataVolume dataBalance={dataBalance?.previousPeriod} />
              </PTabsItem>
            </PTabs>
          </div>
        </Col>

        <Col className='banner-grid'>
          <RegisteredBanner />
        </Col>
      </Row>
    </Container>
  )
}

export default EuRel3Active
