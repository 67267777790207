import React from 'react'
import './ProgressBar.scss'

export const ProgressBar = ({ progress }: { progress?: number }): JSX.Element => {
  progress = Math.min(Math.max(progress || 0, 0), 100)

  return (
    <div role='progressbar' aria-label='Remaining data transfer' className='progress-bar' data-testid='progress-bar'>
      <span style={{ width: progress + '%' }} />
    </div>
  )
}
