import React from 'react'
import { PText } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'

interface DataVolumeStatusLabelProps {
  messageId: string
  dataTestId?: string
  balanceError?: boolean
}

const DataVolumeText = ({ messageId, dataTestId, balanceError }: DataVolumeStatusLabelProps): JSX.Element => {
  return (
    <PText data-testid={dataTestId} color={balanceError ? 'contrast-low' : 'primary'}>
      <FormattedMessage id={messageId} />
    </PText>
  )
}

export default DataVolumeText
