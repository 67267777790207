import React from 'react'
import { PHeading } from '@porsche-design-system/components-react'
import CurrentBalanceLabel from './current-balance-info/CurrentBalanceLabel'
import { ProgressBar } from './progress-bar/ProgressBar'
import { DataUnit } from '../../api/CommonApi'
import StartingBalanceLabel from './starting-balance-info/StartingBalanceLabel'
import './DataVolumeGauge.scss'

interface DataVolumeGaugeProps {
  startingBalance?: number
  currentBalance?: number
  remainingBalancePercentage?: number
  dataUnit?: DataUnit
  balanceLoading?: boolean
  balanceError?: boolean
  isTotalBalance?: boolean
}

const DataVolumeGauge = ({
  startingBalance,
  currentBalance,
  remainingBalancePercentage,
  dataUnit = 'GB',
  balanceLoading = false,
  balanceError = false,
  isTotalBalance = false,
}: DataVolumeGaugeProps): JSX.Element => {
  return (
    <>
      <div className='data-volume-gauge'>
        <div data-testid='data-volume-heading'>
          <div className='gauge-flex'>
            <PHeading
              size={isTotalBalance ? 'x-large' : 'large'}
              color='inherit'
              className={balanceError ? 'grey' : ''}
              data-testid='current-balance-headline'
            >
              <CurrentBalanceLabel
                currentBalance={currentBalance}
                dataUnit={dataUnit}
                balanceNotUsed={startingBalance === currentBalance}
                balanceLoading={balanceLoading}
                balanceError={balanceError}
              />
            </PHeading>
            <StartingBalanceLabel startingBalance={startingBalance} dataUnit={dataUnit} balanceError={balanceError} />
          </div>
        </div>
      </div>
      <ProgressBar progress={remainingBalancePercentage} />
    </>
  )
}

export default DataVolumeGauge
