import 'components/top-bar/TopBar.scss'
import { PHeading } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'
import { useMnoInfoContext } from 'hooks/useMnoInfoContext'
import { VodafoneRefs } from 'api/EuApi'
import { RogersRefs } from 'api/CaApi'
import { Col, Container, Row } from 'react-bootstrap'
import React from 'react'
import MnoLogoContainer from './MnoLogoContainer'
import MnoDisplayNameLabel from './MnoDisplayNameLabel'

export const TopBar = ({
  refs,
  displayProviderInfo = true,
  popover,
  registrationState,
}: {
  refs?: VodafoneRefs | RogersRefs
  displayProviderInfo?: boolean
  popover?: JSX.Element
  registrationState?: JSX.Element
}): JSX.Element => {
  const { data: mnoInfo } = useMnoInfoContext()

  return (
    <Container fluid data-testid='top-bar' className='content'>
      <Row>
        <Col sm={12} md={8}>
          <PHeading size='x-large' data-testid='connectivity-header'>
            <FormattedMessage id='app.topBar.header' />
            {popover}
          </PHeading>
          <MnoDisplayNameLabel display={displayProviderInfo} mnoInfo={mnoInfo} refs={refs} />
        </Col>
        <Col className='mno-info' sm={10} md={4}>
          <MnoLogoContainer display={displayProviderInfo} mnoInfo={mnoInfo} refs={refs} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>{registrationState}</Col>
      </Row>
    </Container>
  )
}
