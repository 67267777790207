import { FormattedMessage } from 'react-intl'
import { PText } from '@porsche-design-system/components-react'
import React from 'react'
import { MnoInfo } from '../../model/uistate/ConnectivityProductUIState'
import { VodafoneRefs } from '../../api/EuApi'
import { RogersRefs } from '../../api/CaApi'

interface MnoDisplayNameLabelProps {
  display: boolean
  mnoInfo?: MnoInfo
  refs?: VodafoneRefs | RogersRefs
}

const MnoDisplayNameLabel = ({ display, mnoInfo, refs }: MnoDisplayNameLabelProps): JSX.Element => {
  return display ? (
    <PText className='top-bar-description' role='complementary' data-testid='provider-text' size='x-small'>
      <FormattedMessage id='app.topBar.provider' />{' '}
      <a href={refs?.contactDetails?.imprint} target='_blank' rel='noreferrer'>
        {mnoInfo?.displayName}
      </a>
    </PText>
  ) : (
    <></>
  )
}

export default MnoDisplayNameLabel
