import { useIntl } from 'react-intl'
import { PLinkPure } from '@porsche-design-system/components-react'
import { useMemo } from 'react'
import { useLang, useVinFromState } from '../../hooks/hooks'
import { useMnoInfoContext } from '../../hooks/useMnoInfoContext'

interface BackToLinkProps {
  label?: string
  connectStoreContractUrl?: string
}

const BackToLink = ({ label, connectStoreContractUrl }: BackToLinkProps): JSX.Element => {
  const intl = useIntl()
  const lang = useLang()
  const vin = useVinFromState()
  const productInfo = useMnoInfoContext()

  const goBackUrl = useMemo(
    () =>
      productInfo.data?.country && vin && lang
        ? `${connectStoreContractUrl}/${productInfo.data.country}/${lang}/overview?vin=${vin}`
        : connectStoreContractUrl,
    [connectStoreContractUrl, lang, productInfo.data?.country, vin]
  )

  return (
    <PLinkPure icon='arrow-head-left' size='small' className='back-to-link'>
      <a href={goBackUrl} data-testid='back-to-link'>
        {label && intl.formatMessage({ id: label })}
      </a>
    </PLinkPure>
  )
}

export default BackToLink
