import { FutureResult } from '../../../model/FutureResult'
import { ConnectivityProductUIState } from '../../../model/uistate/ConnectivityProductUIState'
import { Col, Row } from 'react-bootstrap'
import { ACTIVE } from '../../../utils/path'
import React, { Suspense } from 'react'
import WrappedSpinner from '../../wrapped-spinner/WrappedSpinner'
import { Route, Routes } from 'react-router-dom'
import { GuardedRoute } from '../../guarded-route/GuardedRoute'
import EuRel3Active from '../active/EuRel3Active'
import { usePath } from '../../../hooks/hooks'
import { TopBar } from '../../top-bar/TopBar'

interface EuRel3PageProps {
  connectivityProductState: FutureResult<ConnectivityProductUIState>
}

const EuRel3Page = ({ connectivityProductState }: EuRel3PageProps): JSX.Element => {
  const path = usePath(connectivityProductState)

  return (
    <>
      <Row>
        <Col xs={{ span: 10, offset: 1 }} data-testid='mno-content-eu-rel4'>
          <TopBar displayProviderInfo={false} />
          <div>
            <Suspense fallback={<WrappedSpinner aria={{ 'aria-label': 'Redirecting to page' }} />}>
              <Routes>
                <Route
                  path={`/${ACTIVE}`}
                  element={
                    <GuardedRoute loading={false} authorized={path === ACTIVE} redirectTo=''>
                      <EuRel3Active />
                    </GuardedRoute>
                  }
                />
              </Routes>
            </Suspense>
          </div>
          {connectivityProductState.pending && <WrappedSpinner aria={{ 'aria-label': 'Loading mno refs' }} />}
        </Col>
      </Row>
    </>
  )
}

export default EuRel3Page
