import { PSpinner } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'
import React, { useMemo } from 'react'
import { DataUnit } from '../../../api/CommonApi'

interface CurrentBalanceLabelProps {
  currentBalance?: number
  dataUnit?: DataUnit
  balanceNotUsed: boolean
  balanceLoading: boolean
  balanceError: boolean
}

const CurrentBalanceLabel = ({
  currentBalance,
  dataUnit,
  balanceNotUsed,
  balanceLoading,
  balanceError,
}: CurrentBalanceLabelProps): JSX.Element => {
  const currentBalanceLabel = useMemo(() => {
    if (balanceError) {
      return 0
    }
    return `${balanceNotUsed ? currentBalance?.toString() : currentBalance?.toFixed(2)} ${dataUnit}`
  }, [balanceNotUsed, currentBalance, dataUnit, balanceError])

  if (balanceLoading) {
    return <PSpinner data-testid='suspension-spinner' size='small' />
  }

  if (balanceError) {
    return (
      <div data-testid='balance-not-available-text'>
        <FormattedMessage id='app.registered.dataVolume.balanceNotAvailable' />
      </div>
    )
  }

  return <div data-testid='current-balance-label'>{currentBalanceLabel}</div>
}

export default CurrentBalanceLabel
