export const iitcWidgetTemplateHtml = `
<style>
    :host {
      font-family: Porsche Next, "Segoe UI", sans-serif;
    }
    
    #widget-content {
      background-color: #fff;
      border-radius: 12px;
      padding: 16px 20px;
      min-height: 64px;
      display: flex;
     
     @media(max-width: 1000px) {
       display: block;
     }
      
      #iitc-action-pending {
         display: flex;
         align-items: center;
         gap: 20px;
         width: 100%;
      
         @media (max-width: 1300px) {
          justify-content: center;
          flex-direction: column;
          gap: 16px;
        }
        
        .ctaStatic {
           width: 100%;
        }
          
        #go-to-button {
           width: max-content;
        }  
        @media (max-width: 1300px) {
          .ctaButton {
             width: 100%;
          }
          #go-to-button {
             width: 100%;
          }
        }
      }
    }
    
    #iitc-loading-spinner {
        margin: auto auto;
    }
    
    #success-icon-and-text {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    #go-to-button {
      align-self: baseline;
    }
    #iitc-widget-modal-provider-container {
      display: flex;
      gap: 0.5rem;
    }
    #iitc-widget-modal-info-loading {
      display: none;
       align-items: center;
      gap: 0.5rem;
    }
    #iitc-widget-modal-info-created {
      display: none;
      flex-direction: column;
      gap: 1rem;
    }
    #iitc-widget-modal-logo-href {
      display: flex;
      align-self: center;
    }  
    #iitc-widget-modal-logo {
      height: 1rem;
      align-self: center;
    }
    .display-flex {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    .display-grid {
      display: grid;
    }
    #iitc-widget-modal-error-wrapper {
      display: none;
    }
    #iitc-widget-modal-provider-container {
     margin-bottom: 1rem;
    }
 
      .ctaIcon,
      .doneIcon {
        img{
          width: 58px;
          height: 58px;
        }
          width: 58px;
          height: 58px;
      }
      
      .ctaButton {
          margin-left: auto;
      }
      
      .ctaStatic {
          display: flex;
          align-items: center;
          gap: 20px;
      }
      .doneText {
          color: #197e10;
      }
</style>

<div id='iitc-widget-container' data-testid='widget-container'>
  <div id='widget-content'>
      <div id='iitc-loading-spinner'>
        <p-spinner size='small' />
      </div>
      <div id='iitc-action-pending' style='display: none'>
        <div class='ctaStatic'>
            <div>
              <p-icon size='inherit' class='ctaIcon' name='increase'></p-icon>
            </div>
            <div>
            <p-heading size='medium'>
                <div id='iitc-widget-header' translation-key='iitc.widget.header' data-testid='iitc-widget-header'></div>
            </p-heading>
            </div>
        </div>
        <div class='ctaButton'>
               <p-button id='go-to-button' translation-key='iitc.widget.continue.button' data-testid='widget-button'></p-button>
        </div>
      </div>
      <div id='iitc-action-done' style='display: none' data-testid='iitc-action-done'>
          <div class='ctaStatic'>
              <div>
                <p-icon
                  size='inherit'
                  class='doneIcon'
                  name='check'
                  color='notification-success'
                />
              </div>
              <div>
                <p-heading size='medium' color='inherit' class='doneText'>
                    <div id='iitc-action-done-text' translation-key='iitc.widget.action-done'></div>
                </p-heading>
              </div>
          </div>
       </div>
  </div>
  <p-modal id='iitc-widget-modal' data-testid='iitc-widget-modal' open='false' style='display: none'>
      <div id='iitc-widget-modal-provider-container'>
         <div class='display-flex'>
          <div translation-key='iitc.widget.modal.provider' ></div>
          <a target='_blank' id='iitc-widget-modal-provider-name'  
               data-testid='iitc-widget-modal-provider-name'></a>
         </div>
        <p-divider direction='vertical'></p-divider>
          <a target='_blank' id='iitc-widget-modal-logo-href'>
            <img id='iitc-widget-modal-logo' data-testid='iitc-widget-modal-logo' />    
          </a>
      </div>
      <div class='display-grid'>
        <div id='iitc-widget-modal-info-loading' data-testid='iitc-widget-modal-info-loading'>
           <p-spinner size='small'></p-spinner>
           <p-text translation-key='iitc.widget.modal.content'></p-text>
        </div>
        <div id='iitc-widget-modal-info-created' data-testid='iitc-widget-modal-info-created'>
           <div id='success-icon-and-text'>
             <p-icon name='success'></p-icon>
             <p-text translation-key='iitc.widget.modal.content.created'></p-text>
           </div>
           <div>
             <p-button id='iitc-continue-button' translation-key='iitc.widget.modal.continue.button' data-testid='continue-button'>
             </p-button>
           </div>
        </div>
        <div id='iitc-widget-modal-error-wrapper' data-testid='iitc-widget-modal-error-wrapper'>
          <p-inline-notification id='iitc-widget-modal-error' state='error' dismiss-button='false'/>
        </div>
      </div>
  </p-modal>
</div>
`
