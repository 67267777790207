import React from 'react'
import { MnoInfo } from '../../model/uistate/ConnectivityProductUIState'
import { VodafoneRefs } from '../../api/EuApi'
import { RogersRefs } from '../../api/CaApi'

interface MnoLogoContainerProps {
  display: boolean
  mnoInfo?: MnoInfo
  refs?: VodafoneRefs | RogersRefs
}

const MnoLogoContainer = ({ display, mnoInfo, refs }: MnoLogoContainerProps): JSX.Element => {
  return display ? (
    <div className='mno-logo-container'>
      {mnoInfo?.mno && (
        <a data-testid='mno-logo-href' href={refs?.contactDetails?.imprint} target='_blank' rel='noreferrer'>
          <img
            data-testid='mno-logo'
            className='mno-logo'
            src={`/assets/logo/${mnoInfo.mno.toLowerCase()}/3x.png`}
            alt='Mobile network operator logo'
          />
        </a>
      )}
    </div>
  ) : (
    <></>
  )
}

export default MnoLogoContainer
