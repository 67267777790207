import React from 'react'
import 'components/registered/Registered.scss'

export const RegisteredBanner = (): JSX.Element => (
  <img
    className='registered-banner'
    srcSet={`
            /assets/registered/registered@3x.jpeg 2048w,
            /assets/registered/registered@2x.jpeg 1352w,
            /assets/registered/registered@1x.jpeg 676w
            `}
    src={'/assets/registered/registered@3x.jpeg'}
    alt='Registered banner'
    data-testid='registered-banner'
  />
)
