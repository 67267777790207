import React from 'react'
import Header from 'components/header/Header'
import { Navigation } from 'components/navigation/Navigation'
import 'components/navigation-wrapper/NavigationWrapper.scss'
import { Col, Container, Row } from 'react-bootstrap'
import { FooterConfig } from 'model/footer/FooterConfig'
import { Footer } from 'components/footer/Footer'
import { GoBackTo } from './GoBackTo'

interface NavigationWrapperProps {
  navigationEnv: string
  footerConfig: FooterConfig
  connectStoreContractUrl?: string
  goBackMode?: GoBackTo
  children: JSX.Element
}

export const NavigationWrapper = ({
  navigationEnv,
  footerConfig,
  connectStoreContractUrl,
  goBackMode,
  children,
}: NavigationWrapperProps): JSX.Element => {
  return (
    <div className='navigation-wrapper'>
      <Container className='gx-0' fluid>
        <Row>
          <Col xs={12}>
            <Header navigationEnv={navigationEnv} />
          </Col>

          <Col xs={12}>
            <Col xs={{ span: 10, offset: 1 }} className='navigation'>
              <Navigation connectStoreContractUrl={connectStoreContractUrl} goBackToMode={goBackMode} />
            </Col>
            {children}
          </Col>
        </Row>
      </Container>

      <Footer footerConfig={footerConfig} />
    </div>
  )
}
