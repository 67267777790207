import React from 'react'
import { Route, Routes } from 'react-router-dom'
import EuRel4Page from 'components/eu-rel4/mno-content/EuRel4Page'
import { mnoToPath, productNameToPath } from 'utils/mappings/path-mappings'
import { ConnectivityProductName } from 'model/productName/ConnectivityProductName'
import { useConnectivityProductUIStateFromStore } from 'hooks/hooks'
import { GuardedRoute } from 'components/guarded-route/GuardedRoute'
import CanadaRel4Page from 'components/ca-rel4/mno-content/CanadaRel4Page'
import { NotFound } from 'components/error/not-found/NotFound'
import UsRel4Page from 'components/us-rel4/mno-content/UsRel4Page'
import JpRel4Page from 'components/jp-rel4/mno-content/JpRel4Page'
import Rel4LandingPage from 'components/rel4-landing-page/Rel4LandingPage'
import EuRel3Page from '../eu-rel3/mno-content/EuRel3Page'

const MnoContent = (): JSX.Element => {
  const connectivityProductState = useConnectivityProductUIStateFromStore()
  const productName = connectivityProductState.data?.connectivityProduct

  return (
    <div data-testid='mno-content'>
      <Routes>
        <Route
          path={`/${productNameToPath.get(ConnectivityProductName.EU_RELEASE4_CONNECTIVITY)}/:mno`}
          element={
            <GuardedRoute
              loading={connectivityProductState.pending}
              authorized={productName === ConnectivityProductName.EU_RELEASE4_CONNECTIVITY}
            >
              <Rel4LandingPage connectivityProductState={connectivityProductState} />
            </GuardedRoute>
          }
        />
        <Route
          path={`/${productNameToPath.get(ConnectivityProductName.EU_RELEASE4_CONNECTIVITY)}/:mno/*`}
          element={
            <GuardedRoute
              loading={connectivityProductState.pending}
              authorized={productName === ConnectivityProductName.EU_RELEASE4_CONNECTIVITY}
            >
              <EuRel4Page connectivityProductState={connectivityProductState} />
            </GuardedRoute>
          }
        />
        <Route
          path={`/${productNameToPath.get(ConnectivityProductName.EU_RELEASE3_CONNECTIVITY)}/${mnoToPath.get(
            'VODAFONE'
          )}/*`}
          element={
            <GuardedRoute
              loading={connectivityProductState.pending}
              //@ts-ignore
              authorized={productName === ConnectivityProductName.EU_RELEASE3_CONNECTIVITY}
            >
              <EuRel3Page connectivityProductState={connectivityProductState} />
            </GuardedRoute>
          }
        />
        <Route
          path={`/${productNameToPath.get(ConnectivityProductName.CA_RELEASE4_CONNECTIVITY)}/${mnoToPath.get(
            'ROGERS'
          )}`}
          element={
            <GuardedRoute
              loading={connectivityProductState.pending}
              authorized={productName === ConnectivityProductName.CA_RELEASE4_CONNECTIVITY}
            >
              <Rel4LandingPage connectivityProductState={connectivityProductState} />
            </GuardedRoute>
          }
        />
        <Route
          path={`/${productNameToPath.get(ConnectivityProductName.CA_RELEASE4_CONNECTIVITY)}/${mnoToPath.get(
            'ROGERS'
          )}/*`}
          element={
            <GuardedRoute
              loading={connectivityProductState.pending}
              authorized={productName === ConnectivityProductName.CA_RELEASE4_CONNECTIVITY}
            >
              <CanadaRel4Page connectivityProductState={connectivityProductState} />
            </GuardedRoute>
          }
        />
        <Route
          path={`/${productNameToPath.get(ConnectivityProductName.US_UNLIMITED_CONNECTIVITY)}/${mnoToPath.get(
            'VERIZON'
          )}`}
          element={
            <GuardedRoute
              loading={connectivityProductState.pending}
              authorized={productName === ConnectivityProductName.US_UNLIMITED_CONNECTIVITY}
            >
              <Rel4LandingPage connectivityProductState={connectivityProductState} />
            </GuardedRoute>
          }
        />
        <Route
          path={`/${productNameToPath.get(ConnectivityProductName.US_UNLIMITED_CONNECTIVITY)}/${mnoToPath.get(
            'VERIZON'
          )}/*`}
          element={
            <GuardedRoute
              loading={connectivityProductState.pending}
              authorized={productName === ConnectivityProductName.US_UNLIMITED_CONNECTIVITY}
            >
              <UsRel4Page connectivityProductState={connectivityProductState} />
            </GuardedRoute>
          }
        />
        <Route
          path={`/${productNameToPath.get(ConnectivityProductName.JP_RELEASE4_CONNECTIVITY)}/${mnoToPath.get(
            'SOFTBANK'
          )}`}
          element={
            <GuardedRoute
              loading={connectivityProductState.pending}
              authorized={productName === ConnectivityProductName.JP_RELEASE4_CONNECTIVITY}
            >
              <Rel4LandingPage connectivityProductState={connectivityProductState} />
            </GuardedRoute>
          }
        />
        <Route
          path={`/${productNameToPath.get(ConnectivityProductName.JP_RELEASE4_CONNECTIVITY)}/${mnoToPath.get(
            'SOFTBANK'
          )}/*`}
          element={
            <GuardedRoute
              loading={connectivityProductState.pending}
              authorized={productName === ConnectivityProductName.JP_RELEASE4_CONNECTIVITY}
            >
              <JpRel4Page connectivityProductState={connectivityProductState} />
            </GuardedRoute>
          }
        />
        <Route path={`/*`} element={<NotFound />} />
      </Routes>
    </div>
  )
}

export default MnoContent
