import React, { lazy, Suspense, useMemo, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import NeedHelp from 'components/need-help/NeedHelp'
import { usePath } from 'hooks/hooks'
import { useMnoInfoContext } from 'hooks/useMnoInfoContext'
import { GuardedRoute } from 'components/guarded-route/GuardedRoute'
import { ACTIVATION_ERROR, INACTIVE, NOT_REGISTERED, PENDING, REGISTERED } from 'utils/path'
import { TopBar } from 'components/top-bar/TopBar'
import { SuspensionToggle } from 'components/eu-rel4/suspension-toggle/SuspensionToggle'
import { RegistrationState } from 'components/registration-state/RegistrationState'
import EuCaPopover from 'components/eucapopover/EuCaPopover'
import { Col, Row } from 'react-bootstrap'
import {
  ActiveSuspendedStateData,
  ConnectivityProductUIState,
  ErrorStateData,
  EuActiveStateData,
} from 'model/uistate/ConnectivityProductUIState'
import { VodafoneRefs } from 'api/EuApi'
import { FutureResult } from 'model/FutureResult'
import WrappedSpinner from '../../wrapped-spinner/WrappedSpinner'

interface EuRel4PageProps {
  connectivityProductState: FutureResult<ConnectivityProductUIState>
}

const EuRel4Page = ({ connectivityProductState }: EuRel4PageProps): JSX.Element => {
  const EuRegistered = useMemo(() => lazy(() => import('components/eu-rel4/eu-registered/EuRegistered')), [])
  const EuNotRegistered = useMemo(() => lazy(() => import('components/eu-rel4/eu-not-registered/EuNotRegistered')), [])
  const EuInactiveDataPlan = useMemo(
    () => lazy(() => import('components/eu-rel4/inactive-dataplan/InactiveDataPlan')),
    []
  )

  const EuPending = useMemo(() => lazy(() => import('components/eu-rel4/pending/Pending')), [])

  const ActivationError = useMemo(() => lazy(() => import('components/activation-error/ActivationError')), [])

  const [suspensionToggleClicked, setSuspensionToggleClicked] = useState(false)

  const mnoCountryInfo = useMnoInfoContext()

  const path = usePath(connectivityProductState)

  const refs = connectivityProductState?.data?.refs as VodafoneRefs

  return (
    <>
      <Row>
        <Col xs={{ span: 10, offset: 1 }} data-testid='mno-content-eu-rel4'>
          <TopBar
            refs={refs}
            popover={<EuCaPopover refs={refs} translationPrefix={'app.eu.popover'} />}
            registrationState={
              <RegistrationState registrationState={path}>
                {path === REGISTERED &&
                (connectivityProductState?.data?.state === 'ACTIVE' ||
                  connectivityProductState?.data?.state === 'ACTIVE_SUSPENDED') ? (
                  <SuspensionToggle
                    toggleClicked={suspensionToggleClicked}
                    suspensionState={
                      (connectivityProductState.data as ActiveSuspendedStateData).suspensionStatus || 'ACTIVE'
                    }
                    setToggleClicked={setSuspensionToggleClicked}
                  />
                ) : (
                  <></>
                )}
              </RegistrationState>
            }
          />
          <div>
            <Suspense fallback={<WrappedSpinner aria={{ 'aria-label': 'Redirecting to page' }} />}>
              <Routes>
                <Route
                  path={`/${REGISTERED}`}
                  element={
                    <GuardedRoute loading={false} authorized={path === REGISTERED} redirectTo=''>
                      <EuRegistered refs={refs} suspensionToggleClicked={suspensionToggleClicked} />
                    </GuardedRoute>
                  }
                />
                <Route
                  path={`/${NOT_REGISTERED}`}
                  element={
                    <GuardedRoute
                      loading={connectivityProductState.pending}
                      authorized={path === NOT_REGISTERED}
                      redirectTo=''
                    >
                      <EuNotRegistered refs={refs as VodafoneRefs} />
                    </GuardedRoute>
                  }
                />
                <Route
                  path={`/${INACTIVE}`}
                  element={
                    <GuardedRoute
                      loading={connectivityProductState.pending}
                      authorized={path === INACTIVE}
                      redirectTo=''
                    >
                      <EuInactiveDataPlan refs={refs} />
                    </GuardedRoute>
                  }
                />
                <Route
                  path={`/${PENDING}`}
                  element={
                    <GuardedRoute
                      loading={connectivityProductState.pending}
                      authorized={path === PENDING}
                      redirectTo=''
                    >
                      <EuPending mnoInfo={mnoCountryInfo.data} />
                    </GuardedRoute>
                  }
                />
                <Route
                  path={`/${ACTIVATION_ERROR}`}
                  element={
                    <GuardedRoute
                      loading={connectivityProductState.pending}
                      authorized={path === ACTIVATION_ERROR}
                      redirectTo=''
                    >
                      <ActivationError
                        activationError={(connectivityProductState.data as ErrorStateData).productError}
                      />
                    </GuardedRoute>
                  }
                />
              </Routes>
            </Suspense>
          </div>
          {connectivityProductState.pending && <WrappedSpinner aria={{ 'aria-label': 'Loading mno refs' }} />}
        </Col>
      </Row>
      {refs && (
        <NeedHelp
          iccid={(connectivityProductState.data as EuActiveStateData).iccid}
          mnoAccountId={(connectivityProductState.data as EuActiveStateData).accountId}
          mnoName={connectivityProductState.data?.mnoInformation?.shortDisplayName}
          {...refs}
        />
      )}
    </>
  )
}

export default EuRel4Page
