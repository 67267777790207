import React, { useMemo } from 'react'
import { Rel3DataBalance } from '../../../../model/uistate/ConnectivityProductUIState'
import DataVolumeGauge from '../../../data-volume-gauge/DataVolumeGauge'
import { PHeading } from '@porsche-design-system/components-react'
import Moment from 'react-moment'
import { useLang } from '../../../../hooks/hooks'
import DataVolumeText from '../../../data-volume-text/DataVolumeText'
import { isDataBalancePresent } from '../../../../utils/dataBalance'

interface PreviousDataVolumeProps {
  dataBalance?: Rel3DataBalance
}

const PreviousDataVolume = ({ dataBalance }: PreviousDataVolumeProps): JSX.Element => {
  const locale = useLang()

  const balancePresent = useMemo(() => !!dataBalance && isDataBalancePresent(dataBalance), [dataBalance])

  return (
    <>
      <DataVolumeGauge
        startingBalance={dataBalance?.startingBalance}
        currentBalance={dataBalance?.currentBalance}
        remainingBalancePercentage={dataBalance?.remainingBalancePercentage}
        dataUnit={dataBalance?.dataUnit}
        balanceError={!balancePresent}
      />
      {balancePresent && (
        <div className='state-container'>
          <PHeading size='small'>
            <Moment format={'L'} locale={locale}>
              {dataBalance?.validityStart}
            </Moment>
            {' - '}
            <Moment format={'L'} locale={locale}>
              {dataBalance?.validityEnd}
            </Moment>
          </PHeading>

          <DataVolumeText
            messageId={'app.rel3.active.dataVolume.dataUsagePeriod'}
            dataTestId={'previous-data-volume-state'}
            balanceError={!balancePresent}
          />
        </div>
      )}
    </>
  )
}

export default PreviousDataVolume
