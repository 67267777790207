import 'components/navigation/Navigation.scss'
import { GoBackTo } from '../navigation-wrapper/GoBackTo'
import BackToLink from './BackToLink'

interface NavigationProps {
  connectStoreContractUrl?: string
  goBackToMode?: GoBackTo
}

export const Navigation = ({ connectStoreContractUrl, goBackToMode }: NavigationProps): JSX.Element => {
  if (goBackToMode === GoBackTo.MY_CONTRACTS) {
    return <BackToLink label={'app.navigation.backToContracts'} connectStoreContractUrl={connectStoreContractUrl} />
  }
  if (goBackToMode === GoBackTo.MY_VEHICLES) {
    return <BackToLink label={'app.navigation.backToVehicle'} connectStoreContractUrl={connectStoreContractUrl} />
  }
  return <></>
}
