import React from 'react'

interface CustonIconProps {
  iconName: string
  className?: string
  datatestid?: string
}

const CustomIcon = ({ iconName, className, datatestid }: CustonIconProps): JSX.Element => {
  return (
    <div data-testid={datatestid}>
      <img
        className={className || ''}
        src={`/assets/icons/${iconName}.svg`}
        alt={iconName}
        data-testid={`status-icon-${iconName}`}
      />
    </div>
  )
}

export default CustomIcon
